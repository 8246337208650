html {
  --mdc-dialog-z-index: 1055;

  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 500px;
  --mdc-dialog-container-color: white;

  --mat-dialog-headline-padding: 0 0 0.5rem 0;
  --mdc-dialog-subhead-color: var(--text);
  --mdc-dialog-subhead-font: var(--font_family_brand_regular);
  --mdc-dialog-subhead-line-height: 2.25rem;
  --mdc-dialog-subhead-size: var(--h2_font_size);
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0;

  --mat-dialog-content-padding: 0;
  --mat-dialog-with-actions-content-padding: 0;
  --mdc-dialog-supporting-text-color: var(--text);
  --mdc-dialog-supporting-text-font: var(--font-family-sans-serif);
  --mdc-dialog-supporting-text-line-height: var(--line_height);
  --mdc-dialog-supporting-text-size: var(--font_size_base);
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0em;

  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 0;

  .bgdark {
    --mdc-dialog-container-color: var(--dark_bg);
    --mdc-dialog-subhead-color: var(--dark_fg);
    --mdc-dialog-supporting-text-color: var(--dark_fg);
  }
}

.mdc-dialog {
  &__surface {
    padding: 2.5rem;
    border-radius: var(--border_radius);
    border: 1px solid var(--border_color);

    &::before {
      border: 0 !important;
    }

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%233E53A4%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E);
      background-size: 1rem 1rem;
      color: var(--primary);
      opacity: 1;
      padding: 0.5rem;
      width: 1rem;
      height: 1rem;

      .bgdark & {
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
      }
    }
  }

  &__title {
    margin: 0 0 1.5rem 0 !important;
    border-bottom: 1px solid var(--border_color);

    &::before {
      display: none !important;
    }
  }

  &__content {
    padding: 0 1rem !important;
    margin: 0 -1rem !important;

    // fix bug when setting dialog height
    // https://github.com/angular/components/issues/8493
    max-height: unset !important;
  }

  &__actions {
    min-height: unset !important;
    margin-top: 1.5rem !important;

    .btn {
      min-width: 7rem;
    }
  }
}
