// this is added to the html tag, overridden to allow ngb-tooltip and ngm-popover to show on top of the modal
.cdk-global-scrollblock {
  left: unset !important;
  top: unset !important;
  position: inherit !important;
  overflow-y: hidden !important;
}

// overlay should show on top of the header
.cdk-overlay-container {
  z-index: 1050;
}
